
import Vue from "vue";
import { mapActions } from "vuex";
import { Type } from "../../../../interfaces/list";
import Alertize from "../../../../components/Alertize.vue";
import CustomListForm from "../CustomListForm.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "ListEdit",
	props: {},
	components: { Alertize, CustomListForm },
	data: () => ({
		valid: true,
		custom_list: undefined,
		type_selected: {} as Type,
	}),
	created() {
		this.$nextTick(async function () {
			await this.setLoadingData(TypeLoading.loading);
			this.custom_list = await this.loadCustomList(parseInt(this.getId));
			await this.setLoadingData();
		});
	},
	mounted() {},
	computed: {
		getId() {
			return this.$route.params.id;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},
		async handleSubmit(updates: any) {
			if (!(await this.validate())) return;
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchEditCustomList(updates)
			.then((response) => {
				this.$router.push({ name: "CustomListIndex" });
				this.setLoadingData();
			})
			.catch(err => {
				this.setLoadingData();
			});
			//updates.done();
		},
		handleCancel() {
			this.$router.push({ name: "CustomListIndex" });
		},
		async loadCustomList(id: number) {
			if (!id) return;
			return await this.$store.dispatch("custom_list/show", id);
		},
		async dispatchEditCustomList(updates: any) {
			return this.$store.dispatch("custom_list/update", updates);
		},
	},
});
